import React, { useContext } from "react"
import { navigate } from "gatsby"
import { FirebaseContext } from "../services"
import { Flex } from "../layout/reusables"
import Loader from "react-loader-spinner"

const PrivateRoute = ({ children, path }) => {
    const { authToken, initialized, user } = useContext(FirebaseContext)
    if (
        initialized &&
        !user &&
        typeof window !== "undefined" &&
        window.location.href !== path
    ) {
        navigate(path)
        return null
    }
    if (!initialized) {
        return (
            <Flex align="center" justify="center">
                <Loader type="Circles" color="black" height={30} width={30} />
            </Flex>
        )
    }

    return <>{children}</>
}
export default PrivateRoute
